var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticStyle:{"z-index":"2000"},attrs:{"name":_vm.ADD_TREATMENT_MODAL,"adaptive":true,"height":"auto","width":480,"styles":{ overflow: 'visible' }},on:{"closed":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-container w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between p-1"},[_c('p',{staticClass:"f-16 text-black font-600 mb-0"},[_vm._v("Add New Treatment")]),_c('div',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center\n          rounded-circle\n          cursor-pointer\n        ",staticStyle:{"width":"24px","height":"24px","background-color":"rgb(228, 232, 237)"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fa fa-close",staticStyle:{"color":"rgb(17, 24, 32)"}})])]),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"p-1"},[_c('p',{staticClass:"input-label"},[_vm._v("Treatment Detail")]),_c('InputSelect',{attrs:{"options":_vm.filteredTreatmentOptions,"trackBy":"id","customLabel":function (ref) {
          var treatmentGroup = ref.treatmentGroup;
          var title = ref.title;
          var type = ref.type;

          return ("" + (treatmentGroup ? ((treatmentGroup.name) + " - ") : '') + title + " " + (type === 'ADD_ON' ? '(Add On)' : ''))
        },"clearable":false,"searchable":true,"placeholder":"Select"},on:{"search":_vm.getTreatmentDetailOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var option = ref.option;
return [_c('span',{staticClass:"text-truncate"},[(option.treatmentGroup)?[_vm._v(" "+_vm._s(option.treatmentGroup.name)+" - ")]:_vm._e(),_vm._v(" "+_vm._s(option.title)+" "+_vm._s(option.type === 'ADD_ON' ? '(Add On)' : '')+" ")],2)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-cancel rounded w-100 m-0",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-save rounded w-100 m-0",attrs:{"disabled":!_vm.value},on:{"click":_vm.save}},[_vm._v(" Save ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }