<template>
  <modal
    :name="ADD_TREATMENT_MODAL"
    :adaptive="true"
    height="auto"
    :width="480"
    style="z-index: 2000"
    @closed="$emit('close')"
    :styles="{ overflow: 'visible' }"
  >
    <div class="modal-container w-100">
      <div class="d-flex align-items-center justify-content-between p-1">
        <p class="f-16 text-black font-600 mb-0">Add New Treatment</p>
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            rounded-circle
            cursor-pointer
          "
          style="
            width: 24px;
            height: 24px;
            background-color: rgb(228, 232, 237);
          "
          @click="$emit('close')"
        >
          <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-1">
        <p class="input-label">Treatment Detail</p>
        <InputSelect
          :options="filteredTreatmentOptions"
          v-model="value"
          trackBy="id"
          :customLabel="({ treatmentGroup, title, type }) => {
            return `${treatmentGroup ? `${treatmentGroup.name} - ` : ''}${title} ${type === 'ADD_ON' ? '(Add On)' : ''}`
          }"
          :clearable="false"
          :searchable="true"
          @search="getTreatmentDetailOptions"
          placeholder="Select"
        >
          <template #option="{ option }">
            <span class="text-truncate">
                <template v-if="option.treatmentGroup">
                  {{ option.treatmentGroup.name }} -
                </template>
                {{ option.title }}
                {{ option.type === 'ADD_ON' ? '(Add On)' : '' }}
            </span>
          </template>
        </InputSelect>
      </div>
      <hr class="m-0" />
      <div class="modal-footer">
        <button
          class="btn btn-cancel rounded w-100 m-0"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          class="btn btn-save rounded w-100 m-0"
          @click="save"
          :disabled="!value"
        >
          Save
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import InputSelect from '@/components/InputSelect';

const ADD_TREATMENT_MODAL = 'add-treatment-modal';

export default {
  components: {
    InputSelect,
  },
  props: {
    isOpen: Boolean,
    offlineClinicId: Number,
    selectedTreatmentDetailIds: Array,
  },
  data() {
    return {
      ADD_TREATMENT_MODAL,
      treatmentDetailOptions: [],
      value: null,
    };
  },
  computed: {
    filteredTreatmentOptions() {
      return this.treatmentDetailOptions.filter((filteredTreatment) =>
        this.selectedTreatmentDetailIds.every((everyTreatmentId) => everyTreatmentId !== filteredTreatment.id)
      )
    },
  },
  methods: {
    getTreatmentDetailOptions(search) {
      const params = {
        isActive: true,
        offlineClinicId: this.offlineClinicId,
        search: search || undefined,
      }
      this.axios
        .get(
          '/v2/misc/clinic-treatment-details-groups',
          { params }
        )
        .then((res) => {
          this.treatmentDetailOptions = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    save() {
      this.$emit('save', { treatmentBookingDetailId: this.value.id, openAddTreatmentReviewModal: true });
      this.$emit('close');
    }
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.$emit('save', { treatmentBookingDetailId: null });
          this.value = null;
          this.treatmentDetailOptions = [];
          this.getTreatmentDetailOptions();
        });
        this.$modal.show(ADD_TREATMENT_MODAL);
      } else {
        this.$modal.hide(ADD_TREATMENT_MODAL);
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  overflow: visible;
  height: 100%;
}

.input-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f2f4f6;
}

.btn-cancel {
  border-radius: 8px;
  border: 1px solid #bcc0d1;
  color: #5b5e62;
  background-color: #ffffff;
}

.btn-save {
  color: #ffffff;
  background-color: #0072cd;
}

.btn-save:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}
</style>
