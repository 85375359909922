<template>
  <dropdown-menu
    @open="handleOpenDropdown"
    @close="handleCloseDropdown"
    :width="width"
    :scopeClass="dropdownScopeClass"
    ref="dropdown"
  >
    <div
      slot="trigger"
      class="input"
      :class="{ 'disabled': disabled }"
      :style="styleObject"
      ref="trigger"
    >
      <p v-if="!isOpen || (isOpen && !searchable)" class="text-truncate mb-0">
        {{ renderedLabel }}
      </p>
      <input
        v-if="isOpen && searchable"
        type="text"
        v-model="searchText"
        @input="handleSearch"
        placeholder="Search..."
        class="input-search"
        @click="handleClickSearchInput"
      />
      <span>
        <i
          v-if="clearable && !isOpen && value !== null"
          class="fa fa-close cursor-pointer"
          @click="clearValue"
        ></i>
        <i
          v-if="!clearable || isOpen || value === null"
          class="fa fa-chevron-down"
          :class="{ 'rotate-180': isOpen }"
        ></i>
      </span>
    </div>
    <div slot="content" :style="{ width: `${contentWidth}px` }">
      <div v-if="options.length === 0" class="options">
        <span class="option-not-found">Options not found</span>
      </div>
      <div v-else class="options">
        <div
          v-for="(option, idx) in options"
          :key="idx"
          @click="handleClickOption(option)"
          class="option"
          :class="{
            'option-selected': value !== null && value[trackBy] === option[trackBy],
          }"
        >
          <slot name="option" v-bind:option="option">
            <span class="text-truncate">{{ option[label] }}</span>
          </slot>
        </div>
      </div>
    </div>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from './DropdownMenu/DropdownMenu.vue';
import { debounce } from 'debounce';

export default {
  components: {
    DropdownMenu,
  },
  props: {
    width: {
      type: String,
    },
    styleObject: {
      type: Object,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: Object,
      default: function () {
        return null;
      },
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    dropdownScopeClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: Function,
      default: undefined,
    },
    label: {
      type: String,
      default: 'label',
    },
    trackBy: {
      type: String,
      default: 'value',
    }
  },
  data() {
    return {
      searchText: '',
      isOpen: false,
      contentWidth: 0,
    };
  },
  computed: {
    renderedLabel() {
      if (this.value === null) return this.placeholder;
      if (this.customLabel) return this.customLabel(this.value);

      return this.value[this.label];
    },
  },
  methods: {
    handleClickSearchInput(e) {
      e.stopPropagation();
    },
    clearValue(e) {
      e.stopPropagation();
      this.$emit('input', null);
    },
    handleOpenDropdown() {
      if (this.disabled) return;
      this.isOpen = true;
      this.contentWidth = this.$refs.trigger.offsetWidth;
    },
    handleCloseDropdown() {
      this.isOpen = false;
      this.searchText = '';
      this.$emit('search', '');
    },
    handleClickOption(option) {
      if (this.value?.[this.trackBy] === option[this.trackBy] && this.clearable) {
        this.$emit('input', null);
      } else {
        this.$emit('input', option);
      }
      this.$refs.dropdown.closeDropdown();
    },
    handleSearch: debounce(function () {
      this.$emit('search', this.searchText);
    }, 500),
  },
};
</script>

<style scoped>
.input {
  width: 100%;
  height: 40px;
  padding-inline: 12px;
  border: 1px solid #bcc0d1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.input.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-search {
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
  padding: 0;
  height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

.options {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  padding: 0.5rem;
}

.option-not-found {
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
}

.option {
  cursor: pointer;
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
  display: flex;
}

.option:hover:not(.option-selected) {
  background-color: #f2f4f6;
  border-radius: 8px;
}

.option-selected {
  background-color: #ddedfa;
  border-radius: 8px;
}
</style>
