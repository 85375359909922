<template>
    <div class="mw-100 p-2">
        <div class="section">
            <UserForm
                :userId="Number($route.params.id)"
                :userProps="userData"
                pageType="edit"
                @save="$router.push(getSourceUrl())"
                @cancel="$router.push(getSourceUrl())"
                v-if="!isLoading"
            />
            <div class="card" v-else>
                <div class="card-body">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserForm from './components/UserForm.vue'

    export default {
        components: {
            UserForm,
        },
        data() {
            return {
                userData: {},
                isLoading: true,
            }
        },
        mounted() {
            if (this.hasEditPermission) {
                this.getDetail();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
        },
        methods: {
            getDetail() {
                this.axios.get(`/v2/clinical/offline-clinic-booking/users/${this.$route.params.id}`)
                    .then((res) => {
                        this.userData = res.data.data;
                        this.isLoading = false;
                    })
            },
            getSourceUrl() {
                const userId = this.$route.params.id;
                let sourceUrl = '/offline-clinic-booking';
                if (this.$route.query.source && userId) {
                    switch(this.$route.query.source) {
                        case 'create-appointment':
                            sourceUrl = `/offline-clinic-booking/appointment`;
                            break;
                        case 'booking-detail':
                            if (this.$route.query.bookingId) {
                                sourceUrl = `/offline-clinic-booking/${this.$route.query.bookingId}`;
                            }
                            break;
                    }
                }
                return sourceUrl;
            },
        },
    }
</script>