<template>
    <div class="mw-100 p-2">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Notes</p>
                <hr>
                <div class="input-group" v-if="hasEditPermission">
                  <textarea
                    maxlength="200"
                    rows="1"
                    class="form-control"
                    placeholder="Write notes"
                    style="resize: none; border-radius: 6px 0 0 6px;"
                    v-model="state.notes"
                    :disabled="['COMPLETED','CANCELED'].includes(state.status.enum)"
                  >
                  </textarea>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-blue-secondary"
                      :style="{'opacity': ['COMPLETED','CANCELED'].includes(state.status.enum) ? 0.5 : 1}"
                      type="button"
                      style="border-radius: 0 6px 6px 0;"
                      @click="!['COMPLETED','CANCELED'].includes(state.status.enum) && updateNotes()"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="f-14 font-500" v-else>{{ state.notes || '-' }}</div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center" style="gap: 12px;">
                  <p class="f-16 font-600 mb-0" style="flex: 1;">Treatment Information</p>
                  <button
                    class="btn btn-outline-blue-secondary rounded d-flex align-items-center cursor-pointer"
                    style="flex: 0 0 auto; padding: 8px 14px 8px 12px;"
                    v-if="
                      hasEditPermission &&
                      !['COMPLETED','CANCELED'].includes(state.status.enum)
                    "
                    @click="isAddTreatmentModalOpen = true;"
                  >
                    <img
                      src="/assets/img/icon/add-icon-blue.svg"
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      class="f-14"
                      style="color: #0072CD; margin-left: 4px;"
                    >
                      Add Treatment
                    </span>
                  </button>
                </div>
                <hr>
                <div style="overflow-x: auto">
                  <div class="booking-detail-container">
                    <div
                      class="booking-detail-card"
                      v-for="(bookingDetail, bookingDetailIdx) in state.bookingDetails" v-bind:key="bookingDetail.id"
                      :style="`border: 1px solid ${bookingDetail.status.textColor};`"
                    >
                      <div
                        class="vertical-line-treatment"
                        v-if="bookingDetailIdx !== state.bookingDetails.length - 1"
                      >
                      </div>
                      <div
                        class="status-indicator-treatment"
                        :style="`
                          background: ${bookingDetail.status.textColor};
                          outline: ${bookingDetail.status.backgroundColor} solid 4px;
                        `"
                      >
                      </div>
                      <div class="d-flex justify-content-between align-items-center f-14" style="gap: 12px;">
                        <div class="d-flex align-items-center" style="flex: 1;">
                          <img
                            :src="`
                              ${
                                bookingDetail.status.enum === 'DONE'
                                  ? '/assets/img/icon/done-icon-green.svg'
                                  : '/assets/img/icon/time-icon-black.svg'
                              }
                            `"
                            width="20"
                            height="20"
                            alt=""
                          />
                          <p
                            class="font-600 mb-0"
                            :style="`
                              flex: 1;
                              margin-left: 8px;
                              color: ${
                                bookingDetail.status.enum === 'DONE'
                                  ? '#16B777'
                                  : 'inherit'
                              };
                            `"
                          >
                            {{ `${bookingDetail.startTime} - ${bookingDetail.endTime}` }}
                          </p>
                        </div>
                        <div
                          class="d-flex align-items-center cursor-pointer"
                          style="flex: 0 0 auto"
                          v-if="
                            hasEditPermission &&
                            bookingDetail.status.enum === 'NOT_STARTED' &&
                            state.bookingDetails.length > 1
                          "
                          @click="handleDeleteTreatment(bookingDetail.id)"
                        >
                          <img
                            src="/assets/img/icon/delete-icon-red.svg"
                            width="20"
                            height="20"
                            alt=""
                          />
                          <span style="color: #EB5757; margin-left: 4px;">
                            Delete
                          </span>
                        </div>
                      </div>
                      <div class="mt-1 d-flex">
                        <div class="f-14" style="flex: 0 0 170px;">
                          Treatment Detail
                        </div>
                        <div style="flex: 1;">
                          {{ bookingDetail.treatmentDetailTitle }}
                        </div>
                      </div>
                      <div class="d-flex" style="margin-top: 8px;">
                        <div class="f-14" style="flex: 0 0 170px;">
                          Room
                        </div>
                        <div style="flex: 1; gap: 12px;" class="d-flex align-items-center">
                          <p class="f-14 mb-0">
                            {{ bookingDetail.clinicRoom.name }} -
                            {{ getRoomTypeLabel(bookingDetail.clinicRoom.type) }}
                          </p>
                          <!-- TO DO -->
                          <!-- <div
                            v-if="!bookingDetail.clinicRoom.isAvailable"
                            class="flex-shrink-0"
                          >
                            <Badge textColor="#FFFFFF" bgColor="#FFAE62">Used</Badge>
                          </div> -->
                          <img
                            v-if="
                              hasEditPermission &&
                              bookingDetail.status.enum === 'NOT_STARTED'
                            "
                            src="/assets/img/icon/edit-icon-blue.svg"
                            width="18"
                            height="18"
                            alt=""
                            class="flex-shrink-0 cursor-pointer"
                            @click="
                              handleEditRoom({
                                bookingDetailId: bookingDetail.id,
                                clinicRoom: bookingDetail.clinicRoom,
                              })
                            "
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center" style="margin-top: 8px;">
                        <div class="f-14" style="flex: 0 0 170px;">
                          Clinic Team
                        </div>
                        <div
                          class="clinic-team-container"
                          style="flex: 1;"
                        >
                          <div
                            class="clinic-team-card"
                            :style="{
                              cursor: isClinicTeamEditable(bookingDetail) ? 'pointer' : 'unset',
                              opacity: isClinicTeamEditable(bookingDetail) ? 1 : 0.6,
                            }"
                            v-for="clinicTeam in bookingDetail.requiredClinicTeamRoles"
                            v-bind:key="clinicTeam.slug"
                            @click="() => {
                              if (isClinicTeamEditable(bookingDetail)) {
                                handleEditClinicTeam({
                                  bookingDetailId: bookingDetail.id,
                                  clinicTeamRoles: bookingDetail.requiredClinicTeamRoles,
                                  selectedTeamRole: {
                                    slug: clinicTeam.slug,
                                    name: clinicTeam.name,
                                  },
                                });
                              }
                            }"
                          >
                            <ClinicTeamChips
                              :clinicTeam="clinicTeam.assignedClinicTeam"
                              :role="{
                                slug: clinicTeam.slug,
                                name: clinicTeam.name,
                              }"
                            ></ClinicTeamChips>
                            <div
                              class="line-clamp-1"
                              :style="{
                                flex: 1,
                                color: clinicTeam.assignedClinicTeam &&
                                  clinicTeam.assignedClinicTeam.name &&
                                  isClinicTeamEditable(bookingDetail)
                                    ? 'inherit' : '#98989A'
                                ,
                              }"
                            >
                              {{ clinicTeam.assignedClinicTeam && clinicTeam.assignedClinicTeam.name || clinicTeam.name }}
                            </div>
                            <img
                              src="/assets/img/icon/edit-icon-blue.svg"
                              width="18"
                              height="18"
                              alt=""
                              v-if="isClinicTeamEditable(bookingDetail)"
                            />
                          </div>
                        </div>
                      </div>
                      <template
                        v-if="
                          hasEditPermission &&
                          ['CHECKED_IN','ON_TREATMENT'].includes(state.status.enum) &&
                          ['NOT_STARTED','ON_TREATMENT'].includes(bookingDetail.status.enum)
                        "
                      >
                        <hr>
                        <div class="d-flex justify-content-end">
                          <button
                            class="btn rounded f-14"
                            :class="{
                              'btn-blue-secondary': bookingDetail.status.enum === 'NOT_STARTED',
                              'btn-success-secondary': bookingDetail.status.enum === 'ON_TREATMENT'
                            }"
                            style="flex: 0 0 auto; padding: 12px;"
                            @click="
                              updateBookingDetailStatus({
                                enumValue: bookingDetail.status.enum,
                                bookingDetailId: bookingDetail.id,
                              })
                            "
                          >
                            {{ bookingDetail.status.enum === 'NOT_STARTED' ? 'Start treatment' : 'Mark as done' }}
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body f-14">
                <p class="f-16 font-600">Booking Information</p>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary align-self-center">Status</div>
                  <div class="col-7 font-500 text-right">
                    <div
                      :class="{ 'btn btn-sm': state.status && state.status.enum }"
                      :style="{
                        'backgroundColor': state.status && state.status.backgroundColor,
                        'color': state.status && state.status.textColor
                      }"
                    >
                      {{ state.status.label || '-' }}
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Booking ID</div>
                  <div class="col-7 font-500 text-right">{{ state.bookingNumber || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Booking Date</div>
                  <div class="col-7 font-500 text-right">
                    {{ state.bookingDate || '-' | moment("DD MMMM YYYY, HH:mm") }}{{ `${state.bookingDate ? ' WIB' : ''}` }}
                  </div>
                </div>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Clinic Name</div>
                  <div class="col-7 font-500 text-right">{{  state.clinicName || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Treatment Date</div>
                  <div class="col-7 font-500 text-right">{{ state.treatmentDate || '-' | moment("DD MMMM YYYY") }}</div>
                </div>
                <div class="row">
                  <div class="col-5 text-secondary">Treatment Time</div>
                  <div class="col-7 font-500 text-right">{{  state.treatmentTime || '-' }}</div>
                </div>
                <div
                  class="mt-1"
                  style="
                    background: rgb(242, 244, 246);
                    border-left: 4px solid rgb(121,142,163);
                    padding: 12px 12px 12px 16px;
                  "
                  v-if="['CANCELED'].includes(state.status.enum)"
                >
                  <div class="font-500">Cancel Reason</div>
                  <div>{{ state.cancelReason || '-'  }}</div>
                </div>
                <hr
                  v-if="
                    hasEditPermission &&
                    state.status.enum &&
                    !['COMPLETED', 'CANCELED'].includes(state.status.enum) &&
                    generateCtaText(state.status.enum) !== ''
                  "
                >
                <button
                  class="btn btn-blue-secondary w-100 rounded"
                  v-if="
                    hasEditPermission &&
                    state.status.enum &&
                    !['COMPLETED', 'CANCELED'].includes(state.status.enum) &&
                    generateCtaText(state.status.enum) !== ''
                  "
                  @click="
                    state.status.enum === 'ON_TREATMENT'
                      ? openCompleteBookingModal()
                      : updateBookingStatus({ enumValue: state.status.enum })
                  "
                >
                  {{ generateCtaText(state.status.enum) }}
                </button>
                <!-- Temporary commented out until the task is assigned -->
                <!-- <button class="btn btn-outline-blue-secondary mt-1 w-100 rounded">Reschedule</button> -->
                <button
                  v-if="hasEditPermission && ['BOOKED', 'CONFIRMED'].includes(state.status.enum)"
                  class="btn btn-outline-danger-secondary mt-1 w-100 rounded"
                  @click="openCancelReasonModal()"
                >
                  Cancel Booking
                </button>
              </div>
            </div>
            <div class="card">
              <div class="card-body f-14">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="f-16 font-600 mb-0" style="flex: 1;">User Information</p>
                  <router-link
                    :to="`/offline-clinic-booking/user/${state.user.id}/edit?source=booking-detail&bookingId=${state.bookingNumber}`"
                    class="d-flex align-items-center cursor-pointer"
                    style="flex: 0 0 auto"
                    v-if="
                      hasEditPermission &&
                      state.user.id &&
                      state.status.enum !== 'CANCELED'
                    "
                  >
                    <img
                      src="/assets/img/icon/edit-icon-blue.svg"
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      class="f-14"
                      style="color: #0072CD; margin-left: 4px;"
                    >
                      Edit
                    </span>
                  </router-link>
                </div>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">User Name</div>
                  <div class="col-7 font-500 text-right">
                    {{ `${state.user.firstName || '-'}${state.user.lastName ? ` ${state.user.lastName}` : ''}` }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Phone Number</div>
                  <div class="col-7 font-500 text-right">{{ state.user.mobileNumber || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Email</div>
                  <div class="col-7 font-500 text-right">{{ state.user.email || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Sex</div>
                  <div class="col-7 font-500 text-right">{{ generateGender(state.user.gender) }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Identity Card</div>
                  <div class="col-7 font-500 text-right">{{ state.user.idCard || '-' }}</div>
                </div>
                <div class="row">
                  <div class="col-5 text-secondary">EMR Number</div>
                  <div class="col-7 font-500 text-right">{{ state.user.omnicarePatientId || '-' }}</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body f-14">
                <p class="f-16 font-600">Treatment History</p>
                <hr>
                <div v-if="!state.treatmentHistories.length">-</div>
                <div v-else>
                  <div
                    v-for="(treatmentHistory, treatmentHistoryIdx) in treatmentHistories"
                    :key="treatmentHistoryIdx"
                    class="cursor-pointer d-flex align-items-center"
                    :style="`
                      padding-top: ${treatmentHistoryIdx !== 0 ? '16px' : '4px'};
                      padding-bottom: ${
                        treatmentHistoryIdx === treatmentHistories.length - 1 &&
                        state.treatmentHistories.length <= 3
                          ? 'unset' : '16px'
                      };
                      border-bottom: ${
                        treatmentHistoryIdx === treatmentHistories.length - 1 &&
                        state.treatmentHistories.length <= 3
                          ? 'unset' : '1px solid #E4E8ED'
                      };
                    `"
                    @click="openTreatmentHistoryModal(treatmentHistory)"
                  >
                    <div style="flex: 1;">
                      {{ treatmentHistory.treatmentDate | moment("DD MMMM YYYY") }} - {{ treatmentHistory.bookingNumber }}
                    </div>
                    <div style="flex: 0 0 auto; margin-left: 12px;">
                      <img
                        src="/assets/img/icon/arrow-right-icon-black.svg"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    v-if="state.treatmentHistories.length > 3"
                    class="cursor-pointer"
                    style="
                      padding-top: 16px;
                      width: fit-content;
                      color: #0072CD;
                    "
                    @click="seeMoreTreatmentHistory = !seeMoreTreatmentHistory"
                  >
                    {{ seeMoreTreatmentHistory ? 'See less' : 'See more' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body f-14">
                <p class="f-16 font-600">Activity</p>
                <hr>
                <div
                  class="position-relative"
                  :class="{ 'overflow-hidden': activityIdx === 0 && state.activities.length === 1 }"
                  :style="`
                    padding-left: 26px;
                    padding-bottom: ${
                      (activityIdx === 0 && state.activities.length === 1) ||
                      (activityIdx === state.activities.length - 1 && state.activities.length > 1)
                        ? '0' : '21px'
                    };
                    color: ${activityIdx === 0 ? '#0072CD' : 'inherit'};
                  `"
                  v-for="(activity, activityIdx) in state.activities"
                  :key="activityIdx"
                >
                  <div
                    class="vertical-line-activity"
                    v-if="
                      (activityIdx === 0 && state.activities.length === 1) ||
                      (activityIdx !== state.activities.length - 1 && state.activities.length > 1)
                    "
                  >
                  </div>
                  <div
                    class="status-indicator-activity"
                    :style="`
                      background: ${activityIdx === 0 ? '#0072CD' : '#5B5E62'};
                      outline: ${activityIdx === 0 ? '#C2E9F1' : 'transparent'} solid 2px;
                    `"
                  >
                  </div>
                  <div class="font-500 line-clamp-2 activity-title">{{ activity.title }}</div>
                  <div class="f-12">
                    {{ activity.updatedAt | moment("DD MMMM YYYY, HH:mm") }} by {{ activity.updatedBy }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel reason modal -->
        <modal :name="CANCEL_REASON" :adaptive="true" height="auto" :width="480" style="z-index: 2000">
          <ValidationObserver ref="modalCancelReasonObserver" slim>
            <div class="modal-container w-100">
              <div class="d-flex align-items-center justify-content-between mb-1">
                <p class="f-16 text-black font-600 mb-0">Cancel Booking</p>
                <div
                  class="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
                  style="width: 24px; height: 24px; background-color: rgb(228, 232, 237);"
                  @click="closeCancelReasonModal()"
                >
                  <i class="fa fa-close" style="color: rgb(17, 24, 32);"></i>
                </div>
              </div>
              <ValidationProvider name="cancel reason" v-slot="{ errors }" rules="required" slim>
                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                  <textarea
                    maxlength="120"
                    rows="4"
                    class="form-control"
                    :class="{ 'validation-warning': errors[0] }"
                    placeholder="Write the reason"
                    v-model="state.cancelReason"
                    style="resize: none;"
                  >
                  </textarea>
                  <div class="f-12 d-flex align-items-center justify-content-between" style="margin-top: 4px;">
                    <div class="text-muted" v-if="errors[0]">{{ errors[0] }}</div>
                    <div
                      style="flex: 1 0 auto;"
                      class="text-right"
                      :class="{ 'text-muted': errors[0] }"
                    >
                        {{ state.cancelReason === null ? 0 : state.cancelReason.length }}/120
                    </div>
                  </div>
                </div>
              </ValidationProvider>
              <button class="btn btn-primary mt-2 rounded w-100" @click="cancelBooking()">Submit Cancellation</button>
            </div>
          </ValidationObserver>
        </modal>
        <!-- Complete booking modal -->
        <modal :name="COMPLETE_BOOKING" :adaptive="true" height="auto" :width="480" style="z-index: 2000" @closed="getDetail()">
          <div class="modal-container w-100">
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="/assets/img/icon/info-circle-icon-blue.svg"
                width="72"
                height="72"
                alt=""
              />
            </div>
            <p
              class="f-16 font-600 text-center"
              style="margin: 22px 0 24px 0;"
            >
              Are you sure you want to complete this booking?
            </p>
            <div class="d-flex" style="gap: 14px;">
              <button
                class="btn btn-outline-blue-secondary rounded"
                @click="closeCompleteBookingModal()"
                style="flex: 1;"
              >
                Cancel
              </button>
              <button
                class="btn btn-blue-secondary rounded"
                @click="updateBookingStatus({ enumValue: 'ON_TREATMENT', callback: closeCompleteBookingModal })"
                style="flex: 1; white-space: nowrap;"
              >
                Complete the booking
              </button>
            </div>
          </div>
        </modal>
        <!-- Treatment history modal -->
        <modal :name="TREATMENT_HISTORY" :adaptive="true" height="auto" :width="480" style="z-index: 2000">
          <div class="modal-container w-100">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <p class="f-16 text-black font-600 mb-0">{{ `ID: ${treatmentHistoryModalData.bookingNumber}` }}</p>
              <div
                class="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
                style="width: 24px; height: 24px; background-color: rgb(228, 232, 237);"
                @click="closeTreatmentHistoryModal()"
              >
                <i class="fa fa-close" style="color: rgb(17, 24, 32);"></i>
              </div>
            </div>
            <hr>
            <div class="row" style="margin-bottom: 4px;">
              <div class="col-5">Clinic Name</div>
              <div class="col-7 font-500 text-right text-black">{{ treatmentHistoryModalData.clinicName }}</div>
            </div>
            <div class="row" style="margin-bottom: 4px;">
              <div class="col-5">Treatment Date</div>
              <div class="col-7 font-500 text-right text-black">
                {{ treatmentHistoryModalData.treatmentDate | moment("DD MMMM YYYY") }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Treatment Time</div>
              <div class="col-7 font-500 text-right text-black">{{ treatmentHistoryModalData.treatmentTime }}</div>
            </div>
            <hr>
            <div class="font-500 text-black">Treatment</div>
            <ul style="margin-bottom: 0; padding-left: 18px;">
              <li
                v-for="(treatmentDetail, treatmentDetailIdx) in treatmentHistoryModalData.treatments"
                :key="treatmentDetailIdx"
              >
                {{ `${treatmentDetail.name} with ${treatmentDetail.pic}` }}
              </li>
            </ul>
            <hr>
            <div class="font-500 text-black">Notes</div>
            <div>{{ treatmentHistoryModalData.notes || '-' }}</div>
          </div>
        </modal>
        <!-- Edit room modal -->
        <EditRoomModal
          :isOpen="isEditRoomModalOpen"
          :bookingDetailId="editedRoomBookingDetailId"
          :selectedValue="editedRoomSelectedValue"
          @close="isEditRoomModalOpen = false"
          @save="getDetail()"
        ></EditRoomModal>

        <!-- Edit clinic team modal -->
        <EditClinicTeamModal
          :isOpen="isEditClinicTeamModalOpen"
          :bookingDetailId="editedClinicTeamBookingDetailId"
          :selectedValues="editedClinicTeamSelectedValues"
          :activeTab="editedClinicTeamActiveTab"
          @close="isEditClinicTeamModalOpen = false"
          @save="getDetail()"
        ></EditClinicTeamModal>

        <!-- Delete treatment modal -->
        <DeleteTreatmentModal
          :isOpen="isDeleteTreatmentModalOpen"
          :deletedTreatmentBookingDetailId="deletedTreatmentBookingDetailId"
          @close="
            isDeleteTreatmentModalOpen = false;
            getDetail();
          "
        ></DeleteTreatmentModal>

        <!-- Add treatment modal -->
        <AddTreatmentModal
          :isOpen="isAddTreatmentModalOpen"
          :offlineClinicId="state.clinicId"
          :selectedTreatmentDetailIds="state.bookingDetails.map((bookingDetail) => bookingDetail.treatmentDetailId)"
          @close="isAddTreatmentModalOpen = false"
          @save="handleAddTreatment"
        ></AddTreatmentModal>

        <!-- Add treatment review modal -->
        <AddTreatmentReviewModal
          :bookingNumber="state.bookingNumber"
          :addedTreatmentBookingDetailId="addedTreatmentBookingDetailId"
          :isOpen="isAddTreatmentReviewModalOpen"
          @close="
            isAddTreatmentReviewModalOpen = false;
            getDetail();
          "
        ></AddTreatmentReviewModal>
    </div>
</template>
<script>
import ClinicTeamChips from './components/ClinicTeamChips.vue';
import EditRoomModal from './components/EditRoomModal.vue';
import EditClinicTeamModal from './components/EditClinicTeamModal.vue';
import DeleteTreatmentModal from './components/DeleteTreatmentModal.vue';
import AddTreatmentModal from './components/AddTreatmentModal.vue';
import AddTreatmentReviewModal from './components/AddTreatmentReviewModal.vue';

const CANCEL_REASON ='cancel-reason';
const COMPLETE_BOOKING = 'complete-booking';
const TREATMENT_HISTORY = 'treatment-history';

export default {
    components: {
      ClinicTeamChips,
      EditRoomModal,
      EditClinicTeamModal,
      DeleteTreatmentModal,
      AddTreatmentModal,
      AddTreatmentReviewModal,
    },
    data() {
      return {
        CANCEL_REASON,
        COMPLETE_BOOKING,
        TREATMENT_HISTORY,
        state: {
          id: null,
          activities: [],
          bookingDate: null,
          bookingDetails: [],
          bookingNumber: null,
          cancelReason: null,
          clinicId: null,
          clinicName: null,
          notes: null,
          status: {},
          treatmentDate: null,
          treatmentTime: null,
          treatmentHistories: [],
          user: {},
        },
        seeMoreTreatmentHistory: false,
        // treatment history modal
        treatmentHistoryModalData: {},
        // edit room modal
        isEditRoomModalOpen: false,
        editedRoomBookingDetailId: null,
        editedRoomSelectedValue: null,
        // edit clinic team modal
        isEditClinicTeamModalOpen: false,
        editedClinicTeamBookingDetailId: null,
        editedClinicTeamSelectedValues: [],
        editedClinicTeamActiveTab: null,
        // delete treatment modal
        isDeleteTreatmentModalOpen: false,
        deletedTreatmentBookingDetailId: null,
        // add treatment modal
        isAddTreatmentModalOpen: false,
        addedTreatmentBookingDetailId: null,
        // add treatment review modal
        isAddTreatmentReviewModalOpen: false,
      }
    },
    computed: {
      permissions() {
        return this.$store.getters.permissions;
      },
      hasViewPermission() {
        return this.permissions.includes('offline_clinic_booking:view');
      },
      hasEditPermission() {
        return this.permissions.includes('offline_clinic_booking:edit');
      },
      treatmentHistories() {
        return this.state.treatmentHistories.slice(0, this.seeMoreTreatmentHistory ? this.state.treatmentHistories.length : 3);
      }
    },
    beforeDestroy() {
      this.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        isClinicTeamEditable(bookingDetail) {
          return (
            this.hasEditPermission &&
            !['COMPLETED','CANCELED'].includes(this.state.status.enum) &&
            bookingDetail.status.enum !== 'CANCELED'
          );
        },
        getDetail() {
          this.axios.get(`/v2/clinical/offline-clinic-booking/${this.$route.params.id}`)
            .then((res) => {
              const bookingData = res.data.data;
              this.state = bookingData;
              this.$root.$emit('addAdditionalInfo', bookingData.bookingNumber);
            })
        },
        generateGender(genderValue) {
          switch (genderValue) {
            case 'M':
              return 'Male';
            case 'F':
              return 'Female';
            default:
              return '-';
          }
        },
        generateCtaText(enumValue) {
          let isCompleted = false;
          switch (enumValue) {
            case 'BOOKED':
              return 'Confirm Booking';
            case 'CONFIRMED':
              return 'Check-in';
            case 'ON_TREATMENT':
              isCompleted = this.state.bookingDetails.every((bookingDetail) => {
                return ['DONE','CANCELED'].includes(bookingDetail.status.enum)
              });
              return isCompleted ? 'Mark as Completed' : '';
            default:
              return '';
          }
        },
        updateBookingStatus({ enumValue, callback }) {
          let newStatus = '';
          switch (enumValue) {
            case 'BOOKED':
              newStatus = 'CONFIRMED';
              break;
            case 'CONFIRMED':
              newStatus = 'CHECKED_IN';
              break;
            case 'ON_TREATMENT':
              newStatus = 'COMPLETED';
              break;
          }
          this.axios
            .patch(`/v2/clinical/offline-clinic-booking/${this.state.bookingNumber}`, {
              status: newStatus,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              if (typeof callback === 'function') {
                callback();
              } else {
                this.getDetail();
              }
            });
        },
        updateNotes() {
          if (!this.state.bookingNumber) return;
          this.axios
            .post('/v2/clinical/offline-clinic-booking/notes', {
              bookingId: this.state.bookingNumber,
              notes: this.state.notes || null,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.getDetail();
            });
        },
        async cancelBooking() {
          const isValid = await this.$refs.modalCancelReasonObserver.validate();
          if (isValid) {
            this.axios
            .patch(`/v2/clinical/offline-clinic-booking/${this.state.bookingNumber}`, {
              status: 'CANCELED',
              cancelReason: this.state.cancelReason,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
              this.closeCancelReasonModal();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.getDetail();
            });
          }
        },
        openCancelReasonModal() {
          this.state.cancelReason = null;
          this.$modal.show(CANCEL_REASON);
        },
        closeCancelReasonModal() {
          this.$modal.hide(CANCEL_REASON);
        },
        updateBookingDetailStatus({ enumValue, bookingDetailId }) {
          let newStatus = '';
          switch (enumValue) {
            case 'NOT_STARTED':
              newStatus = 'ON_TREATMENT';
              break;
            case 'ON_TREATMENT':
              newStatus = 'DONE';
              break;
          }
          this.axios
            .patch(`/v2/clinical/offline-clinic-booking-detail/${bookingDetailId}`, {
              status: newStatus,
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.getDetail();
            });
        },
        getRoomTypeLabel(type) {
          switch (type) {
            case 'CONSULT':
              return 'Consult Room';
            case 'FACIAL':
              return 'Facial Room';
            case 'TREATMENT':
              return 'Treatment Room';
            case 'LASER':
              return 'Laser Room';
            default:
              return '';
          }
        },
        openCompleteBookingModal() {
          this.$modal.show(COMPLETE_BOOKING);
        },
        closeCompleteBookingModal() {
          this.$modal.hide(COMPLETE_BOOKING);
          // refetch
          this.getDetail();
        },
        openTreatmentHistoryModal(treatmentHistoryData) {
          this.treatmentHistoryModalData = treatmentHistoryData;
          this.$modal.show(TREATMENT_HISTORY);
        },
        closeTreatmentHistoryModal() {
          this.$modal.hide(TREATMENT_HISTORY);
        },
        handleEditRoom({ bookingDetailId, clinicRoom }) {
          this.isEditRoomModalOpen = true;
          this.editedRoomBookingDetailId = bookingDetailId;
          this.editedRoomSelectedValue = {
            value: clinicRoom.id,
            label: clinicRoom.name,
          };
        },
        handleEditClinicTeam({
          bookingDetailId,
          clinicTeamRoles,
          selectedTeamRole,
        }) {
          this.isEditClinicTeamModalOpen = true;
          this.editedClinicTeamBookingDetailId = bookingDetailId;
          this.editedClinicTeamSelectedValues = clinicTeamRoles;
          this.editedClinicTeamActiveTab = {
            value: selectedTeamRole.slug,
            label: selectedTeamRole.name,
          };
        },
        handleDeleteTreatment(treatmentBookingDetailId) {
          this.isDeleteTreatmentModalOpen = true;
          this.deletedTreatmentBookingDetailId = treatmentBookingDetailId;
        },
        handleAddTreatment({ treatmentBookingDetailId, openAddTreatmentReviewModal = false }) {
          this.addedTreatmentBookingDetailId = treatmentBookingDetailId;
          if (openAddTreatmentReviewModal) {
            this.isAddTreatmentReviewModalOpen = true;
          }
        },
    },
    mounted() {
      this.$moment.locale('en');
      if (this.hasViewPermission || this.hasEditPermission) {
        this.getDetail();
      } else {
        window.location.assign('/offline-clinic-booking');
      }
    },
}
</script>

<style scoped>
  .modal-container{
    overflow: auto;
    padding: 1rem;
    height: 100%;
  }
  .booking-detail-container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 24px;
    padding-left: 48px;
  }
  .booking-detail-card {
    position: relative;
    border-radius: 8px;
    padding: 24px;
  }
  .status-indicator-treatment {
    position: absolute;
    top: 26px;
    left: -45px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
  .vertical-line-treatment {
    position: absolute;
    height: 100%;
    background: #5B5E62;
    width: 1px;
    left: -37.5px;
    top: 47px;
  }
  .clinic-team-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
  }
  .clinic-team-card {
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 3px 8px;
  }
  .line-clamp-1 {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-all;
  }
  .line-clamp-2 {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-all;
  }
  .line-clamp-2.activity-title:hover {
    line-clamp: unset !important;
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 1220px) {
    .booking-detail-container {
      min-width: 800px;
    }
  }
  .btn-blue-secondary {
    border-color: #0072CD;
    background-color: #0072CD;
    color: #FFFFFF;
  }
  .btn-outline-blue-secondary {
    border: 1px solid #0072CD;
    color: #0072CD;
  }
  .btn-success-secondary {
    border-color: #16B777;
    background-color: #16B777;
    color: #FFFFFF;
  }
  .btn-outline-danger-secondary {
    border: 1px solid #EB5757;
    color : #EB5757;
  }
  .status-indicator-activity {
    position: absolute;
    top: 6px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
  .vertical-line-activity {
    position: absolute;
    height: 100%;
    background: #5B5E62;
    width: 1px;
    left: 5.5px;
    top: 6px;
  }
</style>